import axios from 'axios'
import store from '../store/index';
import qs from 'qs'

const http = axios.create({
    baseURL: store.state.serviceUrl,
    timeout: 10000
});

//配置拦截器
/***
 * //显示加载动画种类
 * config.animation=this.constant.Animation.ALL/PART
 * //出现错误时是否弹出错误并中止
 * config.alertError=true/false
 * //出现错误时是否直接返回错误
 * config.returnError=true/false
 * //出现错误时是否忽略
 * config.ignoreError=true/false
 * //忽略拦截处理
 * config.ignoreHandle=true
 */
http.interceptors.request.use(config => {
    if (config.ignoreHandle) {
        return config;
    }
    if (!config.url.startsWith("http")) {
        // 配置token
        let tk = localStorage.getItem("tk");
        if (tk) {
            config.headers['tk'] = tk;
        }
    }
    let contentType = config.headers['Content-Type'];
    if (!contentType || contentType.indexOf("application/x-www-form-urlencoded") > -1) {
        // 配置content-Type
        config.headers['Content-Type'] = 'application/x-www-form-urlencoded';
        config.data = qs.stringify(config.data);
    }
    if (config.animation !== store.state.Animation.NONE) {
        store.state.loadingNoBack = true;
    }
    return config
}, error => {
    if (error.response.config.animation !== store.state.Animation.NONE) {
        store.state.loadingNoBack = false;
    }
    return new Promise("网络繁忙,请稍候重试");
    // 关闭loading
    // store.commit("setItem", {loading: false});
});
let promise = (response) => {
    if (response.config.animation !== store.state.Animation.NONE) {
        store.state.loadingNoBack = false;
    }
    if (response.config.ignoreHandle) {
        return response;
    }
    if (response.config.url.startsWith("http")) {
        return response;
    }
    if (response.data.code !== store.state.ResultCode.OK) {
        //忽略错误
        if (response.config.ignoreError) {
            return new Promise(() => {
            });
        }
    }
    if (response.data.code === store.state.ResultCode.NO_AUTH) {
        localStorage.removeItem("tk");
        location.href = "/login";
        //中止执行
        return new Promise(() => {
        });
    }
    if (response.data.code === store.state.ResultCode.ERROR) {
        //直接弹出错误,中止后续处理
        if (response.config.alertError) {
            throw new Error(response.data.msg);
        }
        //直接返回错误
        if (response.config.returnError) {
            return response;
        }
        // dialog.alert(response.data.msg, {icon: 0});
        //中止执行
        throw new Error(response.data.msg);
        //  return new Promise(response.data);
    }
    if (response.data.code === store.state.ResultCode.NO_PERMISSION) {
        //中止执行
        if (response.config.ignoreError) {
            return new Promise(() => {
            });
        }
        throw new Error("权限不足");
    }
    if (response.data && response.data.code === store.state.ResultCode.SURE) {
        //确认请求
        return sureRequest(response)
    }
    // 关闭loading
    //store.commit("setItem", {loading: false});
    return response.data;
};
let reject = (error) => {
    if (error.config.animation !== store.state.Animation.NONE) {
        store.state.loadingNoBack = false;
    }
    //忽略错误
    if (error.config.ignoreError) {
        return new Promise(() => {
        });
    }
    // 关闭loading
    //store.commit("setItem", {loading: false});
    let resp = error.response;
    let code = resp ? resp.status : null;
    if (code === 500) {
        throw new Error("系统繁忙,请稍候重试");
    } else if (code === 502) {
        throw new Error("系网络繁忙,请稍候重试");
    } else if (code === 404) {
        throw new Error("系统被外星人带走了,正在抢救");
    } else {
        throw new Error("系统繁忙,请稍候重试");
    }
};
// 接收
http.interceptors.response.use(promise, reject);
/***
 * 确认请求
 * @param response
 * @returns {Promise<AxiosResponse<any>>}
 */
function sureRequest(response) {
    return new Promise(function (resolve,reject) {
        window.$vue.$confirm(response.data.msg, "确认提示", {
            type: "warning"
        }).then(() => {
            let config = response.config;
            config.headers['sure'] = 1;
            axios.request(config).then((data)=>resolve(promise(data)))
        })
        .catch(() => {
            reject();
        });
    })
}
export default http
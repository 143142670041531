import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import installElementPlus from './plugins/element'
import './assets/css/icon.css'
import './assets/css/main.css'
import Print from 'vue-print-nb'
// Global instruction
const app = createApp(App)
installElementPlus(app)
app
    .use(store)
    .use(router)
    .use(Print)
    .mount('#app')
/***
 * 时间格式化
 * @param fmt
 * @returns {*}
 */
Date.prototype.format = function (fmt) {
    let o = {
        "M+": this.getMonth() + 1, //月份
        "d+": this.getDate(), //日
        "H+": this.getHours(), //小时
        "m+": this.getMinutes(), //分
        "s+": this.getSeconds(), //秒
        "q+": Math.floor((this.getMonth() + 3) / 3), //季度
        "S": this.getMilliseconds() //毫秒
    };
    if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (this.getFullYear() + "").substr(4 - RegExp.$1.length));
    for (let k in o)
        if (new RegExp("(" + k + ")").test(fmt)) fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
    return fmt;
}
Number.prototype.toDate = function (fmt) {
    let _this = new Date(this);
    let o = {
        "M+": _this.getMonth() + 1, //月份
        "d+": _this.getDate(), //日
        "H+": _this.getHours(), //小时
        "m+": _this.getMinutes(), //分
        "s+": _this.getSeconds(), //秒
        "q+": Math.floor((_this.getMonth() + 3) / 3), //季度
        "S": _this.getMilliseconds() //毫秒
    };
    if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (_this.getFullYear() + "").substr(4 - RegExp.$1.length));
    for (let k in o)
        if (new RegExp("(" + k + ")").test(fmt)) fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
    return fmt;
}
//去重
Array.prototype.push2 = function (val) {
    if(this.indexOf(val)<0){
        this.push(val);
    }
}
//push多个
Array.prototype.pushMany = function (arr) {
    for (let i = 0; i < arr.length; i++) {
        this.push2(arr[i])
    }
}

window.addEventListener('resize', ()=>{
    store.state.bodyHeight = document.body.clientHeight;
    store.state.bodyWidth = document.body.clientWidth;
});

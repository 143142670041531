import {createStore} from 'vuex'

export default createStore({
    state: {
        tagsList: [],
        collapse: false,
        serviceUrl:"/api/deploy/v1",
        loading: false,
        loadingNoBack: false,
        user:{},
        vue:{},
        commons:[],
        ResultCode: {
            OK: 200,//成功
            ERROR: 300, // 失败
            NO_AUTH: 400, // 未认证
            NO_PERMISSION: 500, // 权限不足
            SURE: 601, // 需确认
            ERROR_UPLOAD: -21, // 文件上传失败
            ERROR_PRETREATMENT: -22, // 文件预上传失败
            ERROR_DOWNLOAD: -3, // 文件下载失败
            ERROR_SEND: -23, // 文件发送失败
        },
        Animation:{
            NONE:0,
            ALL:1,
            PART:2
        },
        //报价清单
        shops:[],
        bodyHeight:document.body.clientHeight,
        bodyWidth:document.body.clientWidth
    },
    mutations: {
        setItem(state,param){
            return state[param.name] = param.value;
        },
        getItem(state,param){
            return state[param.name];
        },
        delTagsItem(state, data) {
            state
                .tagsList
                .splice(data.index, 1);
        },
        setTagsItem(state, data) {
            state
                .tagsList
                .push(data)
        },
        clearTags(state) {
            state.tagsList = []
        },
        closeTagsOther(state, data) {
            state.tagsList = data;
        },
        closeCurrentTag(state, data) {
            for (let i = 0, len = state.tagsList.length; i < len; i++) {
                const item = state.tagsList[i];
                if (item.path === data.$route.fullPath) {
                    if (i < len - 1) {
                        data
                            .$router
                            .push(state.tagsList[i + 1].path);
                    } else if (i > 0) {
                        data
                            .$router
                            .push(state.tagsList[i - 1].path);
                    } else {
                        data
                            .$router
                            .push("/");
                    }
                    state
                        .tagsList
                        .splice(i, 1);
                    break;
                }
            }
        },
        // 侧边栏折叠
        hadndleCollapse(state, data) {
            state.collapse = data;
        }
    },
    actions: {},
    modules: {}
})
import CryptoJS from 'crypto-js/crypto-js'
import html2Canvas from 'html2canvas'
import JsPDF from 'jspdf'
import store from '../store/index';

let k = CryptoJS.enc.Utf8.parse("$this's_aes_key$");
/***
 * aes
 * @type {{encrypt: (function(*=): string), decrypt: (function(*=): string)}}
 */
let common = {
    aes: {
        /**
         * aes加密
         * @param word
         * @returns {string}
         */
        encrypt: function (text) {
            let encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(text), k, {
                iv: k,
                mode: CryptoJS.mode.CBC,
                padding: CryptoJS.pad.Pkcs7
            });
            return "$" + CryptoJS.enc.Base64.stringify(encrypted.ciphertext) + "$";
        },
        /***
         * aes解密
         * @param word
         * @returns {string}
         */
        decrypt: function (text) {
            if (text.startsWith("$") && text.endsWith("$")) {
                text = text.substr(1, text.length - 2);
            } else {
                return text;
            }
            let base64 = CryptoJS.enc.Base64.parse(text);
            let src = CryptoJS.enc.Base64.stringify(base64);
            let decrypt = CryptoJS.AES.decrypt(src, k, {
                iv: k,
                mode: CryptoJS.mode.CBC,
                padding: CryptoJS.pad.Pkcs7
            });
            text = CryptoJS.enc.Utf8.stringify(decrypt).toString();
            return this.decrypt(text);
        }
    },
    //数组查找值并返回指定值
    arrayFindReturn: function (arr, id, child) {
        if (!arr || !id) {
            return null;
        }
        if (child === true) {
            child = "child";
        }
        return this.arrayFindReturn2(arr, id, "id", "name", child);
    },
    //数组查找值并返回指定值
    arrayFindReturn2: function (arr, id, key, val, child) {
        if (!arr || !id) {
            return null;
        }
        for (let i = 0; i < arr.length; i++) {
            if (arr[i][key] === id) {
                if(val.indexOf(",")<0){
                    return arr[i][val];
                }
                let result = "";
                //同时返回多个值
                let vals = val.split(",");
                for (let j = 0; j < vals.length; j++) {
                    let temp = arr[i][vals[j]];
                    if(temp){
                        if(result.length>0){
                            result+="/";
                        }
                        result+=temp;
                    }
                }
                return result;
            }
            if (child && arr[i][child]) {
                let result = this.arrayFindReturn2(arr[i][child], id, key, val, child);
                if (result) {
                    return result;
                }
            }
        }
        return null;
    },
    //数组查找值并返回对像
    arrayFindReturnObj: function (arr, id, key, child) {
        if (!arr || !id) {
            return null;
        }
        if (!key) {
            key = "id";
        }
        if (child) {
            return this.arrayFindReturnObjTree(arr, id, key, child);
        }
        for (let i = 0; i < arr.length; i++) {
            if (arr[i][key] === id) {
                return arr[i];
            }
        }
        return null;
    },
    //数组查找值并返回对像
    arrayFindReturnObjTree: function (arr, id, key, child) {
        for (let i = 0; i < arr.length; i++) {
            if (arr[i][key] === id) {
                return arr[i];
            }
            if (arr[i][child]) {
                let result = this.arrayFindReturnObjTree(arr[i][child], id, key, child);
                if (result) {
                    return result;
                }
            }

        }
        return null;
    },
    //数组提供IDS
    getIds: function (arr, key) {
        if (!key) {
            key = "id";
        }
        let result = [];
        let obj = null;
        for (let i = 0; i < arr.length; i++) {
            obj = arr[i][key];
            if (obj && obj !== -1) {
                result.push2(obj);
            }
        }
        return result;
    },
    //树转对象
    treeToObj: function (arr, key, childKey) {
        if (!key) {
            key = "id";
        }
        if (!childKey) {
            childKey = "child";
        }
        let objs = {};
        this.treeToObj2(arr, key, childKey, objs);
        return objs;
    },
    treeToObj2: function (arr, key, childKey, objs) {
        for (let i = 0; i < arr.length; i++) {
            let id = arr[i][key];
            if (id && id !== -1) {
                objs[id] = arr[i];
            }
            let child = arr[i][childKey];
            if (child && child.length > 0) {
                this.treeToObj2(child, key, childKey, objs);
            }
        }
    },
    //树转对象
    removeDisAudit: function (arr) {
        if (!arr) {
            return arr;
        }
        let result = [];
        for (let i = 0; i < arr.length; i++) {
            if (arr[i]['audit']) {
                result.push(arr[i]);
            }
        }
        return result;
    },
    getPdf: function (id, title, callback) {
        html2Canvas(document.querySelector(id), {
            allowTaint: true
        }).then(function (canvas) {
                let contentWidth = canvas.width;
                let contentHeight = canvas.height;
                let pageHeight = contentWidth / 592.28 * 841.89;
                let leftHeight = contentHeight;
                let position = 0;
                let imgWidth = 595.28;
                let imgHeight = 592.28 / contentWidth * contentHeight;
                let pageData = canvas.toDataURL('image / jpeg',
                    1.0
                )
                let PDF = new JsPDF('', 'pt', 'a4')
                if (leftHeight < pageHeight) {
                    PDF.addImage(pageData, 'JPEG',
                        0, 0, imgWidth, imgHeight
                    )
                } else {
                    while (leftHeight > 0) {
                        PDF.addImage(pageData, 'JPEG',
                            0, position, imgWidth, imgHeight
                        )
                        leftHeight -= pageHeight
                        position -= 841.89
                        if (leftHeight > 0) {
                            PDF.addPage()
                        }
                    }
                }
                PDF.save(title + '.pdf');
                if (callback) {
                    callback();
                }

            }
        )
    },
    //权限检查
    hasAuth() {
        let auths = store.state.user.auth;
        if (!auths || auths.length < 1) {
            return false;
        }
        for (let i = 0; i < arguments.length; i++) {
            if (auths.indexOf(arguments[i]) >= 0) {
                return true;
            }
        }
        return false;
    },
    //处理树结构
    listToTree(data) {
        let result = [];
        let menus = {};
        for (let i = 0; i < data.length; i++) {
            menus[data[i].id] = data[i];
        }
        for (let i = 0; i < data.length; i++) {
            if (data[i].pid === -1) {
                result.push(data[i]);
                continue;
            }
            let item = menus[data[i].pid];
            if (item) {
                if (!item.child) {
                    item['child'] = [];
                }
                item.child.push(data[i]);
            }
        }
        return result;
    },
    //更新表列宽度
    changeColWidth(key, newWidth, column) {
        if (!key || !newWidth || !column) {
            return;
        }
        let item = localStorage.getItem("column_width_" + key);
        if (item) {
            try {
                item = JSON.parse(item);
            } catch (e) {
                item = {};
            }
        } else {
            item = {};
        }
        item[column.property] = newWidth;
        localStorage.setItem("column_width_" + key, JSON.stringify(item));
    },
    //获取表列宽度
    getColWidth(key, column) {
        let item = localStorage.getItem("column_width_" + key);
        if (!item) {
            return null;
        }
        try {
            item = JSON.parse(item);
        } catch (e) {
            return null;
        }
        return item[column];
    },
    //计算高度
    calcTableHeight(height) {
        //加上宽度解决宽度变化时不刷新
        let width = store.state.bodyWidth;
        let search = document.getElementsByClassName("handle-box")[width - width];
        if (search) {
            height = height + search.offsetHeight - 40;
        }
        return store.state.bodyHeight - height;
    },
    //字符串处理
    string(str) {
        return str ? str : '';
    },
    form: {
        //表单变更
        change: function (key) {
            try {
                if (!window.formChange) {
                    window.formChange = {};
                }
                window.formChange[key] = true;
            } catch (e) {
                console.log(e);
            }
        },
        //表单提交
        submit: function (key) {
            try {
                if (window.formChange) {
                    delete window.formChange[key];
                }
            } catch (e) {
                console.log(e)
            }

        },
        //是否存在改动
        check: function (item, callback) {
            try {
                if (window.formChange) {
                    if (window.formChange[item.name]) {
                        if (callback) {
                            if (!window.vue) {
                                callback();
                                return true;
                            }
                            window.vue.$confirm('页面[' + item.title + ']变更数据还未保存,确定要放弃变更吗', '提示', {
                                confirmButtonText: '放弃保存',
                                cancelButtonText: '取消',
                                type: 'warning'
                            }).then(() => {
                                callback();
                            }).catch(() => {

                            });
                        }
                        return true;
                    }
                }
                return false;
            } catch (e) {
                console.log(e)
            }
        }

    },
    //表单提交
    check: function (reg) {
        console.log(this,reg)
    },


}
export default common
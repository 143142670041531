import {createRouter, createWebHistory} from "vue-router";
import Home from "../views/other/Home.vue";


const routes = [
    {
        path: '/',
        redirect: '/dashboard'
    }, {
        path: "/",
        name: "Home",
        component: Home,
        children: [
            {
                path: "/dashboard",
                name: "dashboard",
                meta: {
                    title: '系统首页'
                },
                component: () => import (
                "../views/other/Dashboard.vue")
            }, {
                path: "/user",
                name: "UserList",
                meta: {
                    title: '用户管理'
                },
                component: () => import (
                "../views/base/UserList.vue")
            }, {
                path: "/role",
                name: "RoleList",
                meta: {
                    title: '角色管理'
                },
                component: () => import (
                    "../views/base/RoleList.vue")
            }, {
                path: "/log",
                name: "LogView",
                meta: {
                    title: '日志',
                },
                component: () => import (
                    "../views/stat/LogView.vue")
            },  {
                path: "/base",
                name: "base",
                meta: {
                    title: '基础数据'
                },
                component: () => import (
                "../views/base/BaseList.vue")
            }, {
                path: "/client",
                name: "ClientList",
                meta: {
                    title: '客户端列表',
                },
                component: () => import (
                    "../views/client/ClientList.vue")
            }, {
                path: "/client/server",
                name: "ClientServerList",
                meta: {
                    title: '客户端服务列表',
                },
                component: () => import (
                    "../views/client/ClientServerList.vue")
            }, {
                path: "/server",
                name: "ServerList",
                meta: {
                    title: '服务列表',
                },
                component: () => import (
                    "../views/client/ServerList.vue")
            }, {
                path: "/client/server/scan",
                name: "ClientServerScan",
                meta: {
                    title: '客户端服务扫描',
                },
                component: () => import (
                    "../views/client/ClientServerScan.vue")
            }, {
                path: "/client/server/version/edit",
                name: "EditClientServerVersion",
                meta: {
                    title: '客户端服务版本更新',
                },
                component: () => import (
                    "../views/version/EditClientServerVersion.vue")
            }, {
                path: "/client/version/edit",
                name: "EditClientVersion",
                meta: {
                    title: '客户端版本更新',
                },
                component: () => import (
                    "../views/client/EditClientVersion.vue")
            }, {
                path: "/client/server/version",
                name: "ClientServerVersionList",
                meta: {
                    title: '客户端服务版本列表',
                },
                component: () => import (
                    "../views/version/ClientServerVersionList.vue")
            }, {
                path: "/client/version",
                name: "ClientVersionList",
                meta: {
                    title: '客户端版本列表',
                },
                component: () => import (
                    "../views/client/ClientVersionList.vue")
            }, {
                path: "/app/version",
                name: "AppVersionList",
                meta: {
                    title: 'App版本列表',
                },
                component: () => import (
                    "../views/version/AppVersionList.vue")
            }, {
                path: "/app/version/edit",
                name: "EditAppVersion",
                meta: {
                    title: 'App版本更新',
                },
                component: () => import (
                    "../views/version/EditAppVersion.vue")
            }, {
                path: "/abnormal/mail",
                name: "MailUserList",
                meta: {
                    title: '邮件通知维护',
                },
                component: () => import (
                    "../views/inform/MailUserList.vue")
            }, {
                path: "/abnormal/sms",
                name: "SmsUserList",
                meta: {
                    title: '短信通知维护',
                },
                component: () => import (
                    "../views/inform/SmsUserList.vue")
            }, {
                path: "/abnormal/log",
                name: "InformLogList",
                meta: {
                    title: '异常日志列表',
                },
                component: () => import (
                    "../views/inform/InformLogList.vue")
            }, {
                path: '/404',
                name: '404',
                meta: {
                    title: '找不到页面'
                },
                component: () => import (/* webpackChunkName: "404" */
                '../views/other/404.vue')
            }, {
                path: '/403',
                name: '403',
                meta: {
                    title: '没有权限'
                },
                component: () => import (/* webpackChunkName: "403" */
                '../views/other/403.vue')
            }
        ]
    }, {
        path: "/login",
        name: "Login",
        meta: {
            title: '登录'
        },
        component: () => import (
        "../views/other/Login.vue")
    }
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

router.beforeEach((to, from, next) => {
    document.title = (`${to.meta.title} | 分布式一体化运维服务平台`);
    const tk = localStorage.getItem('tk');
    if (!tk && to.path !== '/login') {
        next('/login');
    } else {
        next();
    }
});

export default router;
<template>
    <Loading/>
    <div class="about">
        <v-header />
        <v-sidebar />
        <div class="content-box" :class="{ 'content-collapse': collapse }">
            <v-tags></v-tags>
            <div class="content">
                <router-view v-slot="{ Component }">
                    <transition name="move" mode="out-in">
                        <keep-alive :include="tagsList">
                            <component :is="Component" />
                        </keep-alive>
                    </transition>
                </router-view>
                <!-- <el-backtop target=".content"></el-backtop> -->
            </div>
        </div>
    </div>
</template>
<script>
import vHeader from "../../components/Header";
import vSidebar from "../../components/Sidebar";
import vTags from "../../components/Tags.vue";
import http from "../../utils/http";
import Loading from "../../components/Loading";
export default {
    components: {
        vHeader,
        vSidebar,
        vTags,
        Loading
    },
    created() {
        //获取用户信息
        this.getUserInfo();
    },
    computed: {
        tagsList() {
            return this.$store.state.tagsList.map(item => item.name);
        },
        collapse() {
            return this.$store.state.collapse;
        }
    },
    methods:{
        /***
         * 获取用户信息
         */
        getUserInfo(){
            let userInfo = sessionStorage.getItem("userInfo");
            if(userInfo){
                try{
                    this.$store.commit("setItem",{name:"user",value:JSON.parse(userInfo)})
                }catch (e) {
                    console.log(e);
                }

            }
            http.post("/user/get/Info", {}, {alertError: true}).then(res => {
                this.$store.commit("setItem",{name:"user",value:res.data})
                sessionStorage.setItem("userInfo",JSON.stringify(res.data));
            }).catch(e=>{
                this.$message.error(e.message);
            })
        }
    }
};
</script>

<template>
    <div class="sidebar">
        <el-menu
                class="sidebar-el-menu"
                :default-active="onRoutes"
                :collapse="collapse"
                background-color="#324157"
                text-color="#bfcbd9"
                active-text-color="#20a0ff"
                unique-opened
                router
        >
            <template v-for="item in items">
                <template v-if="item.child">
                    <el-submenu :index="item.cid" :key="item.id">
                        <template #title>
                            <i :class="item.icon"></i>
                            <span>{{ item.name }}</span>
                        </template>
                        <template v-for="subItem in item.child">
                            <el-submenu
                                    v-if="subItem.child"
                                    :index="subItem.cid"
                                    :key="subItem.id">
                                <template #title>{{ subItem.name }}</template>
                                <el-menu-item
                                        v-for="threeItem in subItem.child"
                                        :key="threeItem.id"
                                        :index="threeItem.cid">{{ threeItem.name }}
                                </el-menu-item>
                            </el-submenu>
                            <el-menu-item
                                    v-else
                                    :key="subItem.id"
                                    :index="subItem.cid"
                            >{{ subItem.name }}
                            </el-menu-item>
                        </template>
                    </el-submenu>
                </template>
                <template v-else>
                    <el-menu-item :index="item.cid" :key="item.id">
                        <i :class="item.icon"></i>
                        <template #title>{{ item.name }}</template>
                    </el-menu-item>
                </template>
            </template>
        </el-menu>
    </div>
</template>

<script>
    import http from "../utils/http";
    import common from "../utils/common";

    export default {
        data() {
            return {
                items: []
            };
        },
        created() {
            this.init();
        },
        methods: {
            init() {
                http.post("/menu/auth/list", {}, {alertError: true}).then(data => {
                    data = data.data;
                    if (data) {
                        //处理菜单
                        this.items = common.listToTree(data);
                    }
                })
            },

        },
        computed: {
            onRoutes() {
                return "/dashboard";
            },
            collapse() {
                return this.$store.state.collapse
            },
            // user() {
            //     return this.$store.state.user;
            // }
        },
        watch: {
            // user() {
            //     this.init();
            // }
        }
    };
</script>

<style scoped>
    .sidebar {
        display: block;
        position: absolute;
        left: 0;
        top: 70px;
        bottom: 0;
        overflow-y: scroll;
    }

    .sidebar::-webkit-scrollbar {
        width: 0;
    }

    .sidebar-el-menu:not(.el-menu--collapse) {
        width: 250px;
    }

    .sidebar > ul {
        height: 100%;
    }
</style>

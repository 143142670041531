<template>
    <router-view />
</template>

<script>
export default {
    created() {
        window.$vue =  this;
    }
};
</script>

<style>
@import "./assets/css/main.css";
@import "./assets/css/color-dark.css";
</style>

<template>
    <div class="header">
        <!-- 折叠按钮 -->
        <div class="collapse-btn" @click="collapseChage">
            <i v-if="!collapse" class="el-icon-s-fold"></i>
            <i v-else class="el-icon-s-unfold"></i>
        </div>
        <div class="logo">分布式一体化运维服务平台</div>
        <div class="header-right">
            <div class="header-user-con">
                <!-- 用户头像 -->
<!--                <div class="user-avator" style="padding: 5px ;background:#FFF;border-radius: 50%">-->
<!--                    <img src="../assets/img/img.png "/>-->
<!--                </div>-->

                <!-- 用户名下拉菜单 -->
                <el-dropdown class="user-name" trigger="click">
                    <span class="el-dropdown-link">
                       &nbsp;&nbsp; {{$store.state.user.name}}
                        <i class="el-icon-caret-bottom"></i>
                    </span>
                    <template #dropdown>
                        <el-dropdown-menu>
                            <el-dropdown-item @click="alertChangePassword=true">修改密码</el-dropdown-item>
                            <el-dropdown-item divided @click="clearCache">清除缓存</el-dropdown-item>
                            <el-dropdown-item  @click="logout">退出登录</el-dropdown-item>
                        </el-dropdown-menu>
                    </template>
                </el-dropdown>
            </div>
        </div>
        <!-- 编辑弹出框 -->
        <el-dialog title="修改登陆密码" v-model="alertChangePassword" width="500px" :close-on-click-modal="false">
            <el-form ref="form" :model="form" :rules="rules" label-width="100px" aria-autocomplete="none">
                <el-form-item label="原密码" prop="oldPassword">
                    <el-input type="password"  v-model="form.oldPassword" placeholder="输入原登陆密码" class="form-item" :clearable="true"></el-input>
                </el-form-item>
                <el-form-item label="新密码" prop="password">
                    <el-input type="password" v-model="form.password" placeholder="输入新的登陆密码" class="form-item" :clearable="true"></el-input>
                </el-form-item>
                <el-form-item label="确认密码" prop="password2">
                    <el-input type="password"  v-model="form.password2" placeholder="再次输入新的登陆密码" class="form-item" :clearable="true"></el-input>
                </el-form-item>
            </el-form>
            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="alertChangePassword = false">取 消</el-button>
                    <el-button type="primary" @click="updatePassword">确 定</el-button>
                </span>
            </template>
        </el-dialog>
    </div>
</template>
<script>
import http from "../utils/http";

export default {
    data() {
        return {
            fullscreen: false,
            message: 2,
            alertChangePassword:false,
            form:{

            },
            rules: {
                oldPassword: [{required: true, message: "原密码不能为空", trigger: "blur"}],
                password: [{required: true, message: "新密码不能为空", trigger: "blur"}],
                password2: [{required: true, trigger: "blur",validator: (rule, value, callback) => {
                        if (this.form.password!==value) {
                            callback(new Error('确认密码输入不一致'));
                            return;
                        }
                        callback();
                    }}],
            }
        };
    },
    created() {
      window.vue = this;
    },
    computed: {
        username() {
            let username = localStorage.getItem("ms_username");
            return username ? username : this.name;
        },
        collapse() {
            return this.$store.state.collapse;
        }
    },
    methods: {
        // 用户名下拉菜单选择事件
        logout(){
            http.post("/user/logout", {}, {ignoreError: true});
            localStorage.removeItem("tk");
            this.$router.push("/login");
        },
        //清除缓存
        clearCache(){
            http.post("/config/refreshCache", {}, {alertError: true}).then(() => {
                this.$message.success("清除成功")
            }).catch(e=>{
                this.$message.error(e.message);
            })
        },
        // 侧边栏折叠
        collapseChage() {
            this.$store.commit("hadndleCollapse", !this.collapse);
        },
        updatePassword(){
            this.$refs.form.validate(valid => {
                if (valid) {
                    http.post("/user/updateLoginPassword", this.form, {alertError: true}).then(() => {
                        this.$message.success("修改成功")
                        this.alertChangePassword=false;
                        this.form={}
                    }).catch(e=>{
                        this.$message.error(e.message);
                    })
                }
            });
        }
    },
    mounted() {
        if (document.body.clientWidth < 1500) {
            this.collapseChage();
        }
    }
};
</script>
<style scoped>
.header {
    position: relative;
    box-sizing: border-box;
    width: 100%;
    height: 70px;
    font-size: 22px;
    color: #fff;
}
.collapse-btn {
    float: left;
    padding: 0 21px;
    cursor: pointer;
    line-height: 70px;
}
.header .logo {
    float: left;
    width: 270px;
    line-height: 70px;
}
.header-right {
    float: right;
    padding-right: 50px;
}
.header-user-con {
    display: flex;
    height: 70px;
    align-items: center;
}
.btn-fullscreen {
    transform: rotate(45deg);
    margin-right: 5px;
    font-size: 24px;
}
.btn-bell,
.btn-fullscreen {
    position: relative;
    width: 30px;
    height: 30px;
    text-align: center;
    border-radius: 15px;
    cursor: pointer;
}
.btn-bell-badge {
    position: absolute;
    right: 0;
    top: -2px;
    width: 8px;
    height: 8px;
    border-radius: 4px;
    background: #f56c6c;
    color: #fff;
}
.btn-bell .el-icon-bell {
    color: #fff;
}
.user-name {
    margin-left: 10px;
}
.user-avator {
    margin-left: 20px;
}
.user-avator img {
    display: block;
    width: 40px;
    height: 40px;
    border-radius: 50%;
}
.el-dropdown-link {
    color: #fff;
    cursor: pointer;
}
.el-dropdown-menu__item {
    text-align: center;
}
</style>
